.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

fieldset {
  padding: 15px !important;
  border-width: 2px !important;
  border-style: groove !important;
  border-color: rgb(192, 192, 192) !important;
  border-image: initial !important;
}

legend {
  font-size: 1em !important;
  font-weight: bold;
  margin-bottom: 24px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-fixed {
  margin-top: 6rem;
}

.card-container {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
  font-size: .8em;
  cursor: pointer;
}

.card-status {
  font-size: .6em;
}

.card-container.purple {
  background: #610dad;
  color: #fff;
}

.card-status .yellow {
  background: #ffc107;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.card-status .red {
  background: #dc3545;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.card-status .blue {
  background: #0dcaf0;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.card-status .green {
  background: #0de480;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.card-status .gray {
  background: #ccc;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

table {
  border: 1px solid #0dcaf0;
}

table tr {
  border: 1px solid #0dcaf0;
}

table tr th, table tr td {
  border: 1px solid #0dcaf0;
  padding: 5px 15px;
  font-size: 11px;
  color: #0dcaf0;
}

table tr td {
  color: #000;
}

table .children {
  padding-left: 40px;
}

table .group td {
  font-style: italic;
}

select {
  margin-left: 5px;
  min-width: 200px;
  height: 40px;
  padding: 5px;
}

a {
  cursor: pointer;
  text-decoration: underline !important;
}

input[type="text"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

textarea {
  width: 100%;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 5px;
}

input[type="number"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="email"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="password"] {
  margin-left: 5px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="checkbox"] {
  margin-left: 8px;
}

.clickable {
  cursor: pointer;
}

table.medical th {
  font-size: 16px;
  color: #000;
}

table.medical td {
  font-size: 14px;
  color: #000;
}

table.medical, table tr, table.medical tr td, table.medical tr th {
  border-color: #000;
}

.personnel-info-table .red {
  background: #dc3545;
  color: rgb(25, 23, 23);
}

.personnel-info-table .red button {
  color: white;
}

.personnel-info-table .dark-red {
  background: #7c0d0e;
  color: #fff;
}

.personnel-info-table .light-red {
  background: #864f50;
  color: #fff;
}

.personnel-info-table .dark-gray {
  background: #777;
  color: #fff;
}

.personnel-info-table .light-green {
  background: #0de480;
  color: #fff;
}

.personnel-info-table .light-green button {
  color: #fff;
}

.personnel-info-table .dark-green {
  background: #198754;
  color: #fff;
}

.personnel-info-table .dark-blue {
  background: #0d6efd;
  color: #fff;
}

.personnel-info-table .light-blue {
  background: #0dcaf0;
  color: #fff;
}

.personnel-info-table .black {
  background: #000;
  color: #fff;
}

.personnel-info-table .yellow {
  background: yellow;
  color: #fff;
}

.personnel-info-table .white {
  background: #fff;
  color: #000;
}

.medical ul li {
  cursor: pointer;
  list-style: none;
  margin-bottom: 8px;
  font-size: 18px;
  color: #0d6efd;
}

.comments-text {
  width: 200px;
  height: 150px;
}

.medical ul li:focus, .medical ul li:hover {
  cursor: pointer;
  list-style: none;
  margin-bottom: 8px;
  font-size: 18px;
  color: #0d6efd;
  text-decoration: underline;
}

.report-field-value {
  background: #eee;
  padding: 4px;
}

.selected {
  background: #ffc107;
}

.customers-container {
  background: #eee;
  padding: 20px;
  width: 100%;
}

.customers-dnd-item-container {
  border: 1px dotted #777;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: move;
}

.customer-dnd-img {
  width:18px;
  height:18px;
  margin-right: 20px;
}

.customer-dnd-item-content {
  padding-left: 20px;
  font-size: 11px;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
}

.customer-delete-btn {
  margin-left: auto;
}

.react-datepicker__input-container input {
  height: 35px;
  width: 100%;
}

.admin-nav a {
  text-decoration: none !important;
}

.login {
  text-align: center;
  margin: auto;
}

.login-container {
  background: #eee;
  padding: 40px 5px;
}

.login-container input {
  min-width: 300px;
}

.btn-login {
  min-width: 300px;
}

.create-route-container {
  background: #eee;
  padding: 15px;
}

.landing {
  color:#0d6efd;
}

.landing-img {
  position: fixed;
  right: 0;
  top: 0;
  background-image: url(../public/images/background.jpg);
  height: 100%;
  width: 100%;
  background-position: right top;
  background-repeat: no-repeat;
  z-index: -1;
}

.landing-content-title {
  height: 100vh;
  padding-top: 5%;
}

.landing-title {
  width: 100%;
}

.landing-content {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-top: 40px;
}

.landing-content a {
  margin-left: 15px;
}

.landing-content h6 {
  margin-bottom: 20px;
}

.logo {
  position: absolute;
  top: 30px;
  left: 40px;
  display: flex;
  align-items: center;
}

.logo-suffix {
  font-size: 14px;
  margin-top: -3px;
  font-weight: 700;
  color: orange;
}

.btn-medical {
  padding-left: 3em !important;
  padding-right: 3em !important;
}

.btn-medical a {
  color: white;
  text-decoration: none !important;
  margin-left: 0;
}

.logo img {
  height: 50px;
}

.customers-pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
}

.customers-pagination li a {
  border-radius: 7px;
  padding: 0.1rem 0.8rem;
  border: none;
  cursor: pointer;
  background: transparent;
  color: #777;
}

.customers-pagination li {
  border: none;
}

.customers-pagination li a {
  border: none;
  text-decoration: none !important;
}

.customers-pagination li.previous a,
.customers-pagination li.next a,
.customers-pagination li.break a {
  border-color: transparent;
  background: transparent;
}

.customers-pagination li.active a {
  background-color: #0366d6 !important;
  border-color: transparent;
  color: white;
  min-width: 32px;
}

.customers-pagination li.disabled a {
  color: grey;
  background: transparent !important;
}

.customers-pagination li.disable,
.customers-pagination li.disabled a {
  cursor: default;
  background: transparent;
}

.modal-fullscreen-xxl-down {
  height: 100%;
  margin: 0;
  width: 100vw;
  max-width: none;
}

.table-button-container {
  min-width: 320px;
}

.sigCanvas {
  border: 1px solid #333;
}

.transfer-select {
  min-width: 50px !important;
  height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 5px;
}

.spinner-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: white;
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 768px) {
  .landing {
    color: black;
  }
}

@media only screen and (min-width: 2000px) {
  .container {
    max-width: 1920px;
  }
}

@media print{
  @page {size: landscape}
}

@media print {
  .noprint{
    display: none !important;
  }
  .container {
    margin-left: 0;
    margin-right:0;
  }
}